export const MenuitemTop=[
    {
        name: 'Heim',
        href: '#home'
    },
  
    {
        name: 'Speisekarte',
        href: '#foodmenu'
    },
   
    {
        name:'Kontaktieren Sie uns',
        href:'#contactus'
    }

]