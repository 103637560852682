import { CKEditor } from '@ckeditor/ckeditor5-react';
import axios from 'axios';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Table } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import { getCookies } from "cookies-next";
import { config } from "../../data/config";
import { useState, useEffect } from "react";
import * as React from 'react';
import TissText from './TissText';
import TissImage from './TissImage';
import TissHeading from './TissHeading';
import { useTranslation } from 'react-i18next';

export default function TissEditor({ component, parent }: any) {
    const { t } = useTranslation();

    let cookie = getCookies();
    const [data, setData] = React.useState<any>(null);

    const boxeditor = {
        editorbutton: "border border-dashed border-gray-400 rounded  cursor-pointer p-1  hover:bg-sky-100 text-center",
        captiontext: "text-[14px] text-center",
        imagebtn: "m-auto"
    }
    const loaditem = async () => {
        console.log(parent);
        try {
            var path = `${config.url}/v1/dashboard/component/getlist/${parent}`;

            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            setData(res.data.message)
        } catch (error) {

        }
    }
    const Gridoption = (i: any) => {

        switch (i) {
            case 1:
                var x = 'sm:col-span-3 col-span-12';
                break;
            case 2:
                var x = 'sm:col-span-6 col-span-12';
                break;
            case 3:
                var x = 'sm:col-span-12 col-span-12';
                break;
            default:
                var x = 'sm:col-span-1 col-span-12';
        }
        console.log(i);

        console.log(x);
        return x;

    }
    const updateItem = async (col: any, id: any) => {
        try {
            var x = 3;
            if (col == 1) { x = 2; }
            if (col == 2) { x = 3; }
            if (col == 3) { x = 1; }
            var path = `${config.url}/v1/dashboard/component/colsupdate/${id}`;
            await axios({
                method: 'post',
                url: path,
                data: {
                    cols: x
                },
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            loaditem();
        } catch (error) {

        }
    }
    const deleteitem = async (id: any) => {
        try {

            var path = `${config.url}/v1/dashboard/component/${id}`;
            await axios({
                method: 'delete',
                url: path,

                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            loaditem();
        } catch (error) {

        }
    }
    const arrowup = async (id: any) => {
        try {

            var path = `${config.url}/v1/dashboard/component/arrowup/${id}`;
            await axios({
                method: 'post',
                url: path,

                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            loaditem();
        } catch (error) {

        }
    }
    const arrowdown = async (id: any) => {
        try {

            var path = `${config.url}/v1/dashboard/component/arrowdown/${id}`;
            await axios({
                method: 'post',
                url: path,

                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            loaditem();
        } catch (error) {

        }
    }
    const changecolor = async(color:any,id:any)=>{
        try {

            var path = `${config.url}/v1/dashboard/component/changecolor/${id}`;
            await axios({
                method: 'post',
                url: path,
                data:{
                    bgcolor:color
                },
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            loaditem();
        } catch (error) {

        }
    }
    const GridoptionImage = (i: any) => {
        switch (i) {
            case 1:
                var x = '/Editor/1.svg';
                break;
            case 2:
                var x = '/Editor/2.svg';
                break;
            case 3:
                var x = '/Editor/3.svg';
                break;
            default:
                var x = '/Editor/3.svg';
        }
        return x;

    }
    useEffect(() => {
        loaditem();
    }, []);
    return (
        <>
            <div className=" ">
                <div className="grid sm:grid-cols-12">

                    <div className="col-span-12 gap-4 p-4">
                        <div className="w-[100%]  bg-slate-500   text-white p-4 mb-3">{t('Preview')}</div>
                        <div className='grid grid-cols-12 gap-3'>
                            {
                                data != null && data.map((item: any) => {
                                    return (
                                        <div className={` ${Gridoption(item.cols)}   shadow min-h-16 `} >
                                            <div className='grid grid-cols-2 p-1'>
                                                <div className='text-left p-1 flex gap-2'>
                                                    <button
                                                        onClick={(e) => {
                                                            updateItem(item.cols, item.id);
                                                        }}
                                                    >
                                                        <img src={GridoptionImage(item.cols)} width={30}></img>
                                                    </button>
                                                    <button
                                                        onClick={(e) => {
                                                            arrowup(item.id);
                                                        }}

                                                    >
                                                        <img src={`/icons/arrowup.svg`} width={20}></img>
                                                    </button>
                                                    <button
                                                        onClick={(e) => {
                                                            arrowdown(item.id);
                                                        }}

                                                    >
                                                        <img src={`/icons/arrowndown.svg`} width={20}></img>

                                                    </button>
                                                    <div className='flex '>
                                                       
                                                        
                                                        </div>

                                                    
                                                    <button
                                                        onClick={(e) => {
                                                            deleteitem(item.id);
                                                        }}

                                                    >
                                                        <img src={`/icons/trashv.svg`} width={24}></img>

                                                    </button>
                                                </div>

                                            </div>
                                            <hr />
                                            <div className='text-right p-1 w-[100%]' style={{backgroundColor:item.bgcolor==null ? '#ffffff' :item.bgcolor}}>
                                                <ChoiceCom data={item} loaditem={loaditem} componentname={item.mode}></ChoiceCom>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        <div className="border bg-white ">
                        <SelectTools component={component} loaditem={loaditem} parent={parent}></SelectTools>

                        </div>
                        <div id="hs-scroll-inside-viewport-modal" className="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none" role="dialog" tabIndex={-1} aria-labelledby="hs-scroll-inside-viewport-modal-label">
                            <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
                                <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">
                                    <div className="flex justify-between items-center py-3 px-4 border-b ">
                                    <SelectTools component={component} loaditem={loaditem} parent={parent}></SelectTools>

                                        <button id="hs-scroll-inside-viewport-modal-label" type="button" className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none" aria-label="Close" data-hs-overlay="#hs-scroll-inside-viewport-modal">
                                            <span className="sr-only">{t('Close')}</span>
                                            <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                <path d="M18 6 6 18"></path>
                                                <path d="m6 6 12 12"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="p-4 overflow-y-auto">
                                        <SelectTools component={component} loaditem={loaditem} parent={parent}></SelectTools>
                                    </div>
                                    <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t ">
                                        <button type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none " data-hs-overlay="#hs-scroll-inside-viewport-modal">
                                            {t('Close')}
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </>
    )
}

function ChoiceCom({ data, loaditem, componentname }: any) {

    switch (componentname) {
        case 'text':
            return <TissText data={data} reload={loaditem}></TissText>;
            break;
        case 'heading':
            return <TissHeading data={data} reload={loaditem}></TissHeading>;
            break;
        case 'image':
            return <TissImage data={data} reload={loaditem}></TissImage>;
            break;
    

            break;
        default:
            return <NoneText></NoneText>;
    }


}
function NoneText({ data, reload }: any) {
    return (
        <div>dsd
        </div>
    )
}


function SelectTools({ component, loaditem, parent }: any) {
    const { t } = useTranslation();

    const boxeditor = {
        editorbutton: "border border-dashed border-gray-400 rounded  cursor-pointer p-1  hover:bg-sky-100 text-center",
        captiontext: "text-[14px] text-center",
        imagebtn: "m-auto"
    }
    let cookie = getCookies();

    const saveitem = async (mode: any) => {
        try {
            var path = `${config.url}/v1/dashboard/component`;

            let res = await axios({
                method: 'post',
                url: path,
                data: {
                    parent: parent,
                    component: component,
                    mode: mode
                },
                headers: {
                    Authorization: 'Bearer ' + cookie['token'],
                }
            });
            loaditem();
            //    setData(res.data.message)
        } catch (error) {

        }
    }
    return (
        <>
            <div className="col-span-2 gap-4 p-4">
                <div className="grid grid-cols-3  sm:grid-cols-12 gap-3 mb-3">
                    <div className={boxeditor.editorbutton} onClick={(e) => {
                        saveitem('text');
                    }}>
                        <img src="/Editor/text.svg" className={boxeditor.imagebtn} width={'70%'}></img>
                        <div className={boxeditor.captiontext}
                        > {t('Text')}</div>
                    </div>
                    <div className={boxeditor.editorbutton}
                        onClick={(e) => {
                            saveitem('heading');
                        }}

                    >
                        <img src="/Editor/h1.svg" className={boxeditor.imagebtn} width={'70%'}></img>
                        <div className={boxeditor.captiontext} >{t('Heading')} </div>
                    </div>
                    <div className={boxeditor.editorbutton}

                        onClick={(e) => {
                            saveitem('image');
                        }}
                    >
                        <img src="/Editor/image.svg" className={boxeditor.imagebtn} width={'70%'}></img>
                        <div className={boxeditor.captiontext} >{t('Image')}  </div>
                    </div>
                    {/* <div className={boxeditor.editorbutton}
                        onClick={(e) => {
                            saveitem('movie');
                        }}
                    >
                        <img src="/Editor/movie.svg" className={boxeditor.imagebtn} width={'70%'}></img>
                        <div className={boxeditor.captiontext} > Movie</div>
                    </div>
                    <div className={boxeditor.editorbutton}
                        onClick={(e) => {
                            saveitem('list');
                        }}
                    >
                        <img src="/Editor/list.svg" className={boxeditor.imagebtn} width={'70%'}></img>
                        <div className={boxeditor.captiontext} > List</div>
                    </div> */}
                    {/* <div className={boxeditor.editorbutton}
                        onClick={(e) => {
                            saveitem('carousel');
                        }}

                    >
                        <img src="/Editor/slider.svg" className={boxeditor.imagebtn} width={'70%'}></img>
                        <div className={boxeditor.captiontext} > Carousel</div>
                    </div>
                    <div className={boxeditor.editorbutton}

                        onClick={(e) => {
                            saveitem('gallery');
                        }}
                    >
                        <img src="/Editor/gallery.svg" className={boxeditor.imagebtn} width={'70%'}></img>
                        <div className={boxeditor.captiontext} > Gallery</div>
                    </div>
                    <div className={boxeditor.editorbutton}
                        onClick={(e) => {
                            saveitem('movie');
                        }}
                    >
                        <img src="/Editor/movie.svg" className={boxeditor.imagebtn} width={'70%'}></img>
                        <div className={boxeditor.captiontext} > Movie</div>
                    </div>
                    <div className={boxeditor.editorbutton}
                        onClick={(e) => {
                            saveitem('list');
                        }}
                    >
                        <img src="/Editor/list.svg" className={boxeditor.imagebtn} width={'70%'}></img>
                        <div className={boxeditor.captiontext} > List</div>
                    </div>
                    <div className={boxeditor.editorbutton}
                        onClick={(e) => {
                            saveitem('table');
                        }}

                    >
                        <img src="/Editor/table.svg" className={boxeditor.imagebtn} width={'70%'}></img>
                        <div className={boxeditor.captiontext} > Table</div>
                    </div>
                    <div className={boxeditor.editorbutton}
                        onClick={(e) => {
                            saveitem('code');
                        }}

                    >
                        <img src="/Editor/code.svg" className={boxeditor.imagebtn} width={'70%'}></img>
                        <div className={boxeditor.captiontext} > Embed COde </div>
                    </div>
                    <div className={boxeditor.editorbutton}
                        onClick={(e) => {
                            saveitem('map');
                        }}


                    >
                        <img src="/Editor/map.svg" className={boxeditor.imagebtn} width={'70%'}></img>
                        <div className={boxeditor.captiontext} > Map</div>
                    </div>
                    <div className={boxeditor.editorbutton}

                        onClick={(e) => {
                            saveitem('chart');
                        }}

                    >
                        <img src="/Editor/chart.svg" className={boxeditor.imagebtn} width={'70%'}></img>
                        <div className={boxeditor.captiontext} > Chart</div>
                    </div> */}

                </div>

            </div>
        </>
    )
}